export const userGroupItems = [
    {
        name: "Portfolio Owner",
        value: "Portfolio Owner",
        index: 0
    },
    {
        name: "Sub-Portfolio Owner",
        value: "Sub-Portfolio Owner",
        index: 1
    },
    {
        name: "Product Owner",
        value: "Product Owner",
        index: 2
    },
    {
        name: "Project Owner",
        value: "Project Owner",
        index: 3
    }
]