import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import axios from "axios";
import {useState} from "react";

import * as React from "react";
import AutocompleteEmail from "./AutocompleteEmail";

function DeleteAccountDialog({audit_user_profile_actions, show, setShow}) {
    const [userEmail, setEmail] = useState('');

    const handleChange = (emailValue) => {
        setEmail(emailValue);
    };


    async function delete_user(userEmail) {
        try {
            await axios.post('/delete_account', null, {
                params:
                    {
                        email: userEmail
                    }
            })
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Dialog
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const audit_event = `Deleted user ${userEmail}.`;
                    console.log(audit_event)
                    audit_user_profile_actions(audit_event);
                    delete_user(userEmail);
                    setShow(!show);

                },
            }}
            open={show}>
            <DialogTitle>Delete User Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select user to delete.
                </DialogContentText>

                <AutocompleteEmail value={userEmail} onChange={handleChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(!show)}>Cancel</Button>
                <Button type="submit">Delete User</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteAccountDialog
