import {useEffect, useState} from "react";
import AutocompleteEmail from "./AutocompleteEmail";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import ModifyUserDialog from "./ModifyUserDialog"

function ModifyUser({show, setShow, audit_user_profile_actions}) {
    const [emailValue, setEmailValue] = useState("");
    const [userData, setUserData] = useState(null);
    const [showDialogue, setDialogueShow] = useState(false);
    const handleChange = (emailValue) => {
            console.log("did this work?")
            console.log(emailValue);
            setEmailValue(emailValue);
            get_specific_user(emailValue);
        }

    async function get_specific_user(email) {
        console.log(`get_user(${email})`);
        const data = await axios.get('/get_user', {
            params:
                {
                    email: email
                }
        });
        console.log(data.data);
        setUserData(data.data);
    }

    return (
         <Dialog
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {

                    setShow(!show);
                },
            }}
            open={show}>
            <DialogTitle>Modify User Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select user to modify.
                </DialogContentText>

                <AutocompleteEmail value={emailValue} onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(!show)}>Cancel</Button>
                <Button onClick={() => setDialogueShow(!showDialogue)}>
                    Select User
                </Button>{showDialogue ? <ModifyUserDialog show={showDialogue} setShow={setDialogueShow}
                                                           userData={userData}
                                                           audit_user_profile_actions={audit_user_profile_actions}
            /> : null}
            </DialogActions>
        </Dialog>

    )
}

export default ModifyUser
