import * as React from "react";
import TextField from "@mui/material/TextField";
import {FormControlLabel, FormLabel, Grid, Typography} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CostLineDataGrid from "./CostLineDataGrid"


export default function ProjectPlanningForm() {

    return (
        <Grid container spacing={2}>
            {/* Title */}
            <Grid item xs={12}><Typography variant={"h2"}>Project Information</Typography></Grid>

            {/* Funding Type */}
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Funding Type</InputLabel>
                    <Select
                        defaultValue={"NAWCAD"}
                        label="FundingType"
                        sx={{display: "flex"}}
                    >
                        <MenuItem value={"NAWCAD"}>NAWCAD</MenuItem>
                        <MenuItem value={"Contracts"}>Contracts</MenuItem>
                        <MenuItem value={"External"}>External Government Support</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {/* Project Name */}
            <Grid item xs={8}>
                <TextField
                    label={"Project Name"}
                    placeholder={"Project Name"}
                    variant={"outlined"}
                    required={true}
                    sx={{display: "flex"}}
                />
            </Grid>

            {/* ERP Date */}
            <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{display: "flex"}}
                        label={"ERP 'as of' Date"}
                    />
                </LocalizationProvider>
            </Grid>

            {/* ERP Totals */}
            <Grid item xs={3}>
                <Typography variant={"h4"} sx={{textAlign: "right"}}>Total Project Estimate: </Typography>
                <Typography variant={"body1"} sx={{textAlign: "right"}}>Total ERP Charges: </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"h4"}>$1,500,000.00</Typography>
                <Typography variant={"body1"}>$1,500,000.00</Typography>
            </Grid>

            {/* Contract Totals */}
            <Grid item xs={3}>
                <Typography variant={"h4"} sx={{textAlign: "right"}}>Contract Estimate: </Typography>
                <Typography variant={"body1"} sx={{textAlign: "right"}}>Total NAVAIR ERP Charges: </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"h4"}>$500,000.00</Typography>
                <Typography variant={"body1"}>$500,000.00</Typography>
            </Grid>


            {/* Portfolio Selection */}
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Portfolio</InputLabel>
                    <Select
                        label="portfolio"
                        defaultValue={"fuelsLubes"}
                        sx={{display: "flex"}}
                    >
                        <MenuItem value={"fuelsLubes"}>Fuels & Lubes</MenuItem>
                        <MenuItem value={"energy"}>Energy</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {/* SubPortfolio Selection */}
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>SubPortfolio</InputLabel>
                    <Select
                        label="subportfolio"
                        defaultValue={"NPRE"}
                        sx={{display: "flex"}}
                    >
                        <MenuItem value={"NPRE"}>NPRE</MenuItem>
                        <MenuItem value={"Mobility"}>Mobility Fuels</MenuItem>
                        <MenuItem value={"PrivateParty"}>Private Party</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                        <MenuItem value={"Core"}>Core</MenuItem>
                        <MenuItem value={"REACTS"}>REACTS</MenuItem>
                        <MenuItem value={"ClimateChange"}>Climate Change</MenuItem>
                        <MenuItem value={"OECIF"}>OECIF</MenuItem>
                        <MenuItem value={"OEPF"}>OEPF</MenuItem>
                        <MenuItem value={"Congressional"}>Congressional</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {/* Project Start Date */}
            <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{display: "flex"}}
                        label={"Project Start"}
                    />
                </LocalizationProvider>
            </Grid>

            {/* Project End Date */}
            <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{display: "flex"}}
                        label={"Project End"}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <CostLineDataGrid />
            </Grid>

        </Grid>
    );
}
