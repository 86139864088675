export const archive_columns = [
    {field: "id", midWidth:150},
    {field: "ingest_filename", midWidth: 700},
    {field: "ingest_date_time", midWidth:500}
]

export const preview_columns = [

    {field: "tier_one", midWidth:150},
    {field: "tier_two", midWidth:150},
    {field: "tier_three", midWidth:150},
    {field: "tier_four", midWidth:150},
    {field: "tier_five", midWidth:150},
    {field: "tier_six", midWidth:150},
    {field: "network", midWidth:150},
    {field: "activity", minWidth: 75},
    {field: "con_name", midWidth:150},
    {field: "planned_cost", midWidth:150},
    {field: "budget", midWidth:150}, {
    field: "commitments", midWidth:150},
    {field: "obligations", midWidth:150},
    {field: "actual_costs", midWidth:150},
    {field: "actual_revenues", midWidth:150},
    {field: "assigned_costs", midWidth:150},
    {field: "available_budget", midWidth:150},
    {field: "is_active", midWidth:50},
    {field: "as_of_date", midWidth:150},
    {field: "file_name", midWidth:150},
    {field: "id", midWidth:150}

]
