import React, { useState, useEffect } from 'react';
import './css/HomePage.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import BurnRate from "./Benchmarks/BurnRate";



export default function HomePage() {


  return (
      <BurnRate/>
  );
}