// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {DataGridPremium, GridToolbar} from '@mui/x-data-grid-premium';
import * as React from "react";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {preview_columns} from "./constants/ArchiveConstants";


export default function PreviewFileDataGrid(props) {
    const [filePreviewData, setFilePreviewData] = useState(null);
    const [filePreviewDataReceived, setFilePreviewDataReceived] = useState(false);
    useEffect(() => {
            console.log(props.rowValue.file_name)

            async function get_file_preview() {
                const data = await axios.get('/get_specific_archived_file',
                    {
                        params:
                            {
                                file_name: props.rowValue.file_name
                            }
                    }
                )
                setFilePreviewData(data.data);
                setFilePreviewDataReceived(true);
            }

            get_file_preview();
        }, []
    )
    if (filePreviewDataReceived === false) {
        return <div className={'loading-container'}><CircularProgress size={"200px"}/></div>
    } else {
        return (
            <div>
                <DataGridPremium
                    columns={[...preview_columns]}
                    initialState={{
                        rowGrouping: {
                            model: ['product'],
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            }
                        }
                    }}
                    showCellVerticalBorder={true}
                    rows={filePreviewData}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                />
                <Button variant='contained' onClick={() => {
                    props.setShow(false)
                }}>Close</Button>
            </div>

        );
    }
}
