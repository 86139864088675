import {useEffect, useState} from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {TextField} from "@mui/material";
import UserGroupSelector from "./UserGroupSelector";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {userGroupItems} from "./UserGroupConstants"
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import axios from "axios";

function ModifyUserDialog({show, setShow, userData, audit_user_profile_actions}) {
    const [userGroup, setUserGroup] = useState(null);
    const [previousUserGroup, setPreviousUserGroup] = useState(null);



    const menuItems = userGroupItems.map((group) => {
        return <MenuItem key={group.index} value={group.value}>{group.name}</MenuItem>
    })


    const handleChange = (event) => {

        setPreviousUserGroup(userData.map((u) => u.userGroup));
        console.log(userData.map((u) => u.userGroup));
        setUserGroup(event.target.value);
    }


    async function updateUser(userID, userGroup) {
        await axios.post('/update_user', null, {
            params:
                {
                    user_id: userID,
                    user_group: userGroup

                }
        })
    }


    return (
        <Dialog
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const audit_event = `Modified user: ${userData.map((u) => u.email)}. From ${previousUserGroup} to ${userGroup} `;
                    const userID = userData.map((u) => u.userID);
                    console.log(audit_event);
                    console.log(typeof(userID), userID[0], userID, userGroup)
                    audit_user_profile_actions(audit_event);
                    updateUser(userID[0], userGroup);
                    setShow(!show);

                },
            }}
            open={show}>
            <DialogTitle>Modify Existing User Account</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    disabled
                    margin="dense"
                    id="userID"
                    name="userID"
                    label={"EDI-PI"}
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={userData.map((u) => u.userID)}
                    value={userData.map((u) => u.userID)}
                />
                <TextField
                    autoFocus
                    disabled
                    required
                    margin="dense"
                    id="firstName"
                    name="first_name"
                    label={"First Name"}
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={userData.map((u) => u.firstName)}
                />
                <TextField
                    autoFocus
                    required
                    disabled
                    margin={"dense"}
                    id={"last_name"}
                    name={"last_name"}
                    label={"Last Name"}
                    type={"text"}
                    fullWidth
                    variant={"standard"}
                    defaultValue={userData.map((u) => u.lastName)}
                />
                <TextField
                    autoFocus
                    required
                    disabled
                    margin={"dense"}
                    id={"email"}
                    name={"email"}
                    label={"email"}
                    type={"text"}
                    fullWidth
                    variant={"standard"}
                    defaultValue={userData.map((u) => u.email)}
                />
                <UserGroupSelector value={userGroup} onChange={handleChange} items={menuItems} label={"User Group"}
                                   inputLabel={"User Group"} defaultValue={userData.map((u) => u.userGroup)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(!show)}>Cancel</Button>
                <Button type="submit">Modify User</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModifyUserDialog
