import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";
import UserGroupSelector from "./UserGroupSelector";
import axios from "axios";
import {useState} from "react";
import {userGroupItems} from "./UserGroupConstants";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";


function AddAccountDialog({audit_user_profile_actions, show, setShow}) {
    const [userGroup, setUserGroup] = useState('');

    const menuItems = userGroupItems.map((group) => {
                       return <MenuItem key={group.index} value={group.value}>{group.name}</MenuItem>
                    })

    const value = "";

    const handleChange = (event) => {
        setUserGroup(event.target.value);
    };
    async function add_new_user(firstName, lastName, userID, userGroup, email) {

        try {
            await axios.post('/add_account', null, {
                params:
                    {
                        user_id: userID,
                        first_name:firstName,
                        user_group: userGroup,
                        email: email,
                        last_name: lastName
                    }
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Dialog
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const user_id = formJson.userID;
                    const first_name = formJson.firstName;
                    const last_name = formJson.lastName;
                    const email = formJson.email;
                    const audit_event = `Added user ${first_name} ${last_name} (${user_id}) and assigned role: ${userGroup}`;

                    const value = add_new_user(first_name, last_name, user_id, userGroup, email);
                    console.log(value)
                    audit_user_profile_actions(audit_event);
                    setShow(!show);

                },
            }}
            open={show}>
            <DialogTitle>Add New User Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To add a new user provide their information.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="userID"
                    name="userID"
                    label={"EDI-PI"}
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label={"First Name"}
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    margin={"dense"}
                    id={"lastName"}
                    name={"lastName"}
                    label={"Last Name"}
                    type={"text"}
                    fullWidth
                    variant={"standard"}/>
                <TextField
                    autoFocus
                    required
                    margin={"dense"}
                    id={"email"}
                    name={"email"}
                    label={"Email"}
                    type={"text"}
                    fullWidth
                    variant={"standard"}/>
                <UserGroupSelector value={userGroup} onChange={handleChange} items={menuItems} label={"User Group"}
                                   inputLabel={"User Group"} defaultValue={value}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(!show)}>Cancel</Button>
                <Button type="submit">Add New User</Button>
            </DialogActions>
        </Dialog>

    )
}

export default AddAccountDialog
