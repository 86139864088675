import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function FileUploadSnackBar(props) {


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    props.setOpen(false);
  };
  let severity = "";
  if (props.status === 200) {
    severity = "success";
    console.log("status = 200")
    console.log(props.status);
    console.log(props.message);

  }else if (props.status === 422) {
    severity = "error";
    console.log("status = 422")
    console.log(props.status);
    console.log(props.message);

  }
  return (
    <div>
      <Snackbar open={props.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical:"top", horizontal:"center"}}>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
            {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
