import React, {Component} from 'react';
import './css/TopNav.css'

export default function TopNav() {
  return (
    <div className={"top-nav-body"}>
        <div className={'app-title'}>
           FLOAPE
        </div>
    </div>
  );
}