import React from 'react'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import BurnRateRollUpLineChartTooltip from "./BurnRateRollUpLineChartTooltip";

function BurnRateRollUpLineChart(props) {

    const rollUp = (data) => {
        let result = {};

        data.forEach((value) => {
            const date_str = value.calendar_date.toString();

            if (result[date_str]) {
                result[date_str].planned += Number(value.planned);
                result[date_str].budget += Number(value.budget);
                result[date_str].pending += Number(value.pending);
                result[date_str].commitments += Number(value.commitments);
                result[date_str].obligations += Number(value.obligations);
                result[date_str].erp_costs += Number(value.erp_costs);
            } else {
                result[date_str] = {
                    planned: Number(value.planned),
                    budget: Number(value.budget),
                    pending: Number(value.pending),
                    commitments: Number(value.commitments),
                    obligations: Number(value.obligations),
                    erp_costs: Number(value.erp_costs),
                };
            }
        });

        let arr_result = Object.keys(result).map((key) => ({
            calendar_date: new Date(key),
            ...result[key]
        }));

        return arr_result;
    };


    /**
     * Function to create a new array with running totals of numerical fields
     * @param {Array} data - Array of JSON objects to be processed
     * @param {Array} fields - Array of fields to calculate running totals for
     * @returns {Array} - New array with running totals
     */
    const calculateRunningTotals = (data, fields) => {
        // Initialize running totals
        const runningTotals = fields.reduce((acc, field) => {
            acc[field] = 0;
            return acc;
        }, {});

        // Calculate running totals
        let result = data.map(item => {
            const newItem = {...item};
            fields.forEach(field => {
                runningTotals[field] += parseFloat(item[field]) || 0;
                newItem[`running_total_${field}`] = runningTotals[field];
            });
            return newItem;
        });
        const max_values = result.at(-1)
        result = result.map((item) => {
            const newItem = {
                ...item,
                max_planned: max_values.running_total_planned,
                max_erp_costs: max_values.running_total_erp_costs
            }
            return newItem
        })
        return result;
    };

    const calculateCalendarDate = (data) => {
        let result = []
        data.forEach((record) => {
            let copy = record
            copy["calendar_date"] = new Date(record.calendar_year, record.calendar_month_number - 1)
            result.push(copy)
        })
        return result.sort((a, b) => a.calendar_date - b.calendar_date)
    }

    const formatDateToYearMonth = (date) => {

        const monthFormatter = new Intl.DateTimeFormat('en-US', {month: 'short'});
        const yearFormatter = new Intl.DateTimeFormat('en-US', {year: '2-digit'});

        const month = monthFormatter.format(date).toUpperCase();
        const year = yearFormatter.format(date);

        return `${month} ${year}`;
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'narrowSymbol'
        }).format(value)
    }

    const handleClick = (e) => {
        if (e && e.activeLabel) {
            // Assuming e.activeLabel is in 'MMM yyyy' format
            const clickedDate = new Date(e.activeLabel);
            props.onMonthClick(clickedDate);
        }
    };

    if (!props.data) {
        return "Loading..."
    }
    return (
        <LineChart
            width={900}
            height={350}
            data={calculateRunningTotals(rollUp(calculateCalendarDate(props.data)), ["erp_costs", "planned"])}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}
            onClick={handleClick}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="calendar_date" tickFormatter={formatDateToYearMonth}/>
            <YAxis
                tickFormatter={formatCurrency}
                tickMargin={0}
                tick={{fontSize: 12}}
            />
            <Tooltip content={<BurnRateRollUpLineChartTooltip/>}
                // TODO: OnClick filter down to projects that have erp_costs that are over/under planned by %10
            />
            <Legend/>
            <Line
                dataKey="running_total_planned"
                name="Planned Costs to Date"
                stroke="#660000"
            />
            <Line
                dataKey="running_total_erp_costs"
                name="ERP Costs to Date"
                stroke="#000080"
            />
            <Line
                dataKey={"max_planned"}
                name={"Benchmark"}
                stroke={"#080808"}
            />
        </LineChart>
    )
}

export default BurnRateRollUpLineChart
