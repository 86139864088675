import Button from '@mui/material/Button';
import React, {useEffect, useState} from 'react'
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Checkbox, FormControlLabel, FormGroup, Grid} from "@mui/material";
import axios from "axios";
import AddAccountDialog from "./AddAccountDialog"
import DeleteAccountDialog from "./DeleteAccountDialog";
import ModifyUser from "./ModifyUser";


export default function AccountManagement() {
    const [portfolioOwner, setPortfolioOwner] = useState(false);
    const [subPortfolioOwner, setSubPortfolioOwner] = useState(false);
    const [userGroup, setUserGroup] = useState(null);
    const adminUser = 1234567890;

    const [showAdd, setAddShow] = useState(false);
    const [showDelete, setDeleteShow] = useState(false);
    const [showModify, setModifyShow] = useState(false);


    async function audit_user_profile_actions(auditEvent) {
        await axios.post('/action_audit', null, {
            params:
                {
                    user_id: adminUser,
                    audit_event: auditEvent
                }
        });
    }



    useEffect(() => {
        async function get_user_profile() {
            try {
                const data = await axios.get(`/account_management`,
                    {params: {user_id: adminUser}})
                console.log(data.data[0].userGroup)
                setUserGroup(data.data[0].userGroup);




            } catch (error) {
                console.log(error)
            }
        }

        get_user_profile()
    }, []);



    const theme = createTheme({
        palette: {
            primary: {
                light: '#1e1e86',
                main: '#000080',
                dark: '#02025c',
                contrastText: '#fff',
            },
            secondary: {
                light: '#fff595',
                main: '#ffd900',
                dark: '#ffa700',
                contrastText: '#000080',
            },
        },
    });

    return (
        <div className={"account-management-button-container"} style={{marginTop:"10px"}} >
            <ThemeProvider theme={theme}>
                <Grid sx={{display:"flex", justifyContent:"center"}}>

                    <Button style={{marginLeft: "auto", justifyContent: "center"}} color={'primary'}
                            variant={"contained"}
                            onClick={() => setDeleteShow(!showDelete)
                    }> Delete
                        Account </Button> {showDelete ? <DeleteAccountDialog setShow={setDeleteShow}
                                                                       show={showDelete}
                                                                       audit_user_profile_actions={audit_user_profile_actions}/>
                    : null}

                    <Button style={{marginLeft: "auto", justifyContent: "center"}} color={'primary'}
                            variant={"contained"}
                            onClick={() => setAddShow(!showAdd)}> Add
                        Account</Button>{showAdd ? <AddAccountDialog setShow={setAddShow} show={showAdd}
                                                                  audit_user_profile_actions={audit_user_profile_actions}/>
                    : null}

                    <Button style={{marginLeft: "auto", justifyContent: "center", marginRight:"auto"}} color={'primary'}
                            variant={"contained"}
                            onClick={() => setModifyShow(!showModify)}>
                        Modify Account</Button>{showModify ? <ModifyUser setShow={setModifyShow} show={showModify}
                    audit_user_profile_actions={audit_user_profile_actions}/>
                    : null}
                    </Grid>
                <div className={"checkbox-grid-container"} style={{marginTop: "5px"}}>
                    <Grid sx={{display: "flex", justifyContent: 'center'}}>
                        <FormGroup className={"checkboxes"} style={{display: 'flex'}}
                                   sx={{position: 'flex', flexDirection: 'row'}}>
                            <FormControlLabel style={{marginLeft: "auto"}}
                                              control={<Checkbox checked={userGroup === "Portfolio Owner"}/>}
                                              label={"Portfolio Owner"}/>

                            <FormControlLabel style={{marginLeft: "auto"}} control={<Checkbox checked={userGroup === "Sub-Portfolio Owner"}/>}
                                              label={"Sub Portfolio Owner"}/>
                            <FormControlLabel style={{marginLeft: "auto"}} control={<Checkbox checked={userGroup === "Product Owner"}/>}
                                              label={"Product Owner"}/>
                            <FormControlLabel style={{marginLeft: "auto"}}
                                              control={<Checkbox checked={userGroup === "Project Owner"}/>}
                                              label={"Project Owner"}/>
                        </FormGroup>
                    </Grid>
                </div>
            </ThemeProvider>
        </div>
    )
}
