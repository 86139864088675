import React, {useState} from 'react'
import MenuItem from "@mui/material/MenuItem";
import {
    portfolioType,
    subPortfolioType,
    tagDropdownOptionsCostType,
    project_tag,
    departmentType
} from "./DropdownOptions"
import BurnRateSelector from "./BurnRateSelector";
import {Typography, Button} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useFetchData} from "../../hooks/useFetchData";
import useFilter from "../../hooks/useFilter";
import BurnRateRollUpLineChart from "./BurnRateRollUpLineChart";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import DataGridProjects from "./DataGridProjects"


function BurnRate() {

    const {data, loading, error} = useFetchData("/benchmark_data")

    //TODO: dynamic lists of sub_portfolio, project tags

    const [showSubPortfolio, setShowSubPortfolio] = useState(true);

    const [filterPredicate, setFilterPredicate] = useState({
        department: "All",
        portfolio: "All",
        sub_portfolio: "All",
        cost_line_type: "All",
        tags: "All",
        min_date: new Date(2023, 10, 1)
    })

    const filteredData = useFilter(data, filterPredicate)

    const portfolioItems = portfolioType.map((portfolio) => {
        return <MenuItem key={portfolio.index} value={portfolio.value}>{portfolio.name}</MenuItem>
    })
    const subPortfolioItems = subPortfolioType.map((subPortfolio) => {
        return <MenuItem key={subPortfolio.index} value={subPortfolio.value}>{subPortfolio.name}</MenuItem>
    })


    const departmentItems = departmentType.map((tag) => {
        return <MenuItem key={tag.index} value={tag.value}>{tag.name}</MenuItem>
    })
    const fiscalYearItems = Array.from(Array(10).keys()).map((i) => {
        let fiscalYear = i + 2022
        return <MenuItem key={fiscalYear} value={fiscalYear}>{fiscalYear}</MenuItem>
    })

    const handleFilterChange = (event) => {
        setFilterPredicate({
            ...filterPredicate,
            [event.target.name]: event.target.value
        })

        if (event.target.name === "portfolio") {
            if (event.target.value === "Energy") {
                setShowSubPortfolio(false)
                setFilterPredicate({
                    ...filterPredicate,
                    sub_portfolio: "All"
                })
            } else {
                setShowSubPortfolio(true)
            }
        }
    }

    const handleMinDateChange = (date) => {
        setFilterPredicate(prevState => ({ ...prevState, min_date: date }));
    }
    const handleMaxDateChange = (date) => {
        setFilterPredicate(prevState => ({ ...prevState, max_date: date }));
    }
    const clearFilter = () =>{ setFilterPredicate({})}
    const handleMonthClick = (month) => {
        // Assuming month is a Date object or a string in 'yyyy-mm' format
        const minDate = new Date(month.getFullYear(), month.getMonth(), 1);
        const maxDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);

        setFilterPredicate(prevState => ({
            ...prevState,
            min_date: minDate,
            max_date: maxDate
        }));
    };

    return (
        <>
            <Typography variant={"h3"}>
                Benchmarks
            </Typography>

            <Grid container spacing={4} sx={{padding: "20px"}}>
                <Grid container item xs={3} spacing={2}>
                    <Grid item xs={12}>Project Filters</Grid>
                    <Grid item xs={12}>
                        <BurnRateSelector value={filterPredicate["portfolio"]}
                                          onChange={handleFilterChange}
                                          items={portfolioItems}
                                          label={"Portfolio"}
                                          inputLabel={"Portfolio"}
                                          name={"portfolio"}
                                          defaultValue={filterPredicate["portfolio"]}
                        />
                    </Grid>
                    {
                        showSubPortfolio &&
                        <Grid item xs={12}>
                            <BurnRateSelector value={filterPredicate["sub_portfolio"]}
                                              onChange={handleFilterChange}
                                              items={subPortfolioItems}
                                              label={"Sub Portfolio"}
                                              inputLabel={"Sub Portfolio"}
                                              name={"sub_portfolio"}
                                              defaultValue={filterPredicate["sub_portfolio"]}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <BurnRateSelector value={filterPredicate["cost_line_type"]}
                                          onChange={handleFilterChange}
                                          url={"/distinct_costline_types"}
                                          label={"Cost Line Tag"}
                                          inputLabel={"Cost Line Tag"}
                                          name={"cost_line_type"}
                                          defaultValue={filterPredicate["cost_line_type"]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BurnRateSelector value={filterPredicate["department"]}
                                          onChange={handleFilterChange}
                                          items={departmentItems}
                                          label={"Department"}
                                          inputLabel={"Department"}
                                          name={"department"}
                                          defaultValue={filterPredicate["department"]}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BurnRateSelector value={filterPredicate["tags"]}
                                          onChange={handleFilterChange}
                                          url={"/distinct_tags"}
                                          label={"Project Tag"}
                                          inputLabel={"Project Tag"}
                                          name={"tag"}
                                          defaultValue={filterPredicate["tags"]}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker value={filterPredicate["date_min"]}
                                        views={["year", "month"]}
                                        onChange={handleMinDateChange}
                                        items={fiscalYearItems}
                                        label={"Starting Date"}
                                        inputLabel={"Starting Date"}
                                        name={"date_min"}
                                        defaultValue={filterPredicate["date_min"]}
                                        maxDate={filterPredicate["date_max"]}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker value={filterPredicate["date_max"]}
                                        views={["year", "month"]}
                                        onChange={handleMaxDateChange}
                                        items={fiscalYearItems}
                                        label={"Ending Date"}
                                        inputLabel={"Ending Date"}
                                        name={"date_max"}
                                        defaultValue={filterPredicate["date_max"]}
                                        minDate={filterPredicate["date_min"]} // TODO: Why doesn't this work?
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={clearFilter}>Show All</Button>
                    </Grid>
                </Grid>
                {data &&
                    <Grid item xs={"auto"}>
                        <BurnRateRollUpLineChart
                            data={filteredData}
                            onMonthClick={handleMonthClick}
                        />
                    </Grid>
                }


                <Grid item xs={12}>
                    <Typography variant={"h3"}>Projects</Typography>
                    {data &&
                        <DataGridProjects
                            rows={filteredData}
                            loading={loading}
                        />
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default BurnRate
