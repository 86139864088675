import { useMemo } from 'react';

const useSpendingStatus = (data) => {
    const aggregateData = useMemo(() => {
        const aggregatedData = {};

        data.forEach((item) => {
            // const key = `${item.project_id}-${item.fiscal_month_number}`;
            const key = item.project_id
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    // id: `${item.project_id}-${item.fiscal_month_number}`, // Ensure unique id
                    id: item.project_id,
                    project_id: item.project_id,
                    fiscal_month_number: item.fiscal_month_number,
                    planned: 0,
                    erp_costs: 0,
                    project_name: item.project_name,
                    fiscal_year: item.fiscal_year,
                    portfolio: item.portfolio,
                    sub_portfolio: item.sub_portfolio,
                    calendar_year: item.calendar_year,
                    calendar_month_number: item.calendar_month_number,
                    department: item.department,
                    start: item.project_start,
                    end: item.project_end,
                    network: item.network,
                    activity: item.activity,
                    as_of_date: item.as_of_date,
                    commitments: item.commitments,
                    product: item.product,
                    budget: item.budget

                };
            }
            aggregatedData[key].planned += Number(item.planned);
            aggregatedData[key].erp_costs += Number(item.erp_costs);
        });

        return Object.values(aggregatedData);
    }, [data]);

    const spendingData = useMemo(() => {
        return aggregateData.map((item) => {
            const diff = item.erp_costs - item.planned;
            const percentageDiff = (diff / item.planned) * 100;

            return {
                ...item,
                status: percentageDiff > 10 ? 'overspent' : percentageDiff < -10 ? 'underspent' : 'on_target'
            };
        });
    }, [aggregateData]);

    return spendingData;
};

export default useSpendingStatus;
