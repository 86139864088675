import React from 'react'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {useFetchData} from "../../hooks/useFetchData";
import MenuItem from "@mui/material/MenuItem";
import {tagDropdownOptionsCostType} from "./DropdownOptions";

function BurnRateSelector(props) {

    const {data, loading, error} = useFetchData(props.url)

    const handleChangeLocally = (event) => {
        props.onChange(event)
    }

    if (loading) {return "loading..."}
    if (error && props.url) {
        return <>{error.message}</>
    }

    const items = props.url && ["All", ...new Set(data.map(item => item))].map((tag, index) => {
        return <MenuItem key={index} value={tag}>{tag}</MenuItem>
    })

    return (
            <FormControl fullWidth>
                <InputLabel id="burn-rate-selector">{props.inputLabel}</InputLabel>
                <Select
                    labelId="burn-rate-selector"
                    id="burn-rate-select"
                    value={props.user}
                    label={props.label}
                    onChange={handleChangeLocally}
                    defaultValue={props.defaultValue}
                    name={props.name}
                >
                    {props.items ? props.items : items}
                </Select>
            </FormControl>
    );
}

export default BurnRateSelector
