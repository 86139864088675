import React from 'react';
import {Tooltip} from 'recharts';

const BurnRateRollUpLineChartTooltip = ({active, payload, label}) => {
    const formatDateToYearMonth = (date) => {

        const monthFormatter = new Intl.DateTimeFormat('en-US', {month: 'short'});
        const yearFormatter = new Intl.DateTimeFormat('en-US', {year: '2-digit'});

        const month = monthFormatter.format(date).toUpperCase();
        const year = yearFormatter.format(date);

        return `${month} ${year}`;
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en', {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'narrowSymbol'
        }).format(value)
    }
    if (active && payload && payload.length) {
        // Assuming the date is in the first payload entry
        const dateValue = payload[0].payload.calendar_date;
        const formattedDate = formatDateToYearMonth(dateValue);

        return (
            <div className="burnraterolluplinecharttooltip"
                 style={{backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc'}}>
                <p className="label">{`Date: ${formattedDate}`}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{color: entry.color}}>
                        {`${entry.name}: ${formatCurrency(entry.value)}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

export default BurnRateRollUpLineChartTooltip;
