import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import {useState} from "react";
import FileUploadSnackBar from "./FileUploadSnackBar";

export default function FileAlertDialog({show, setShow, selectedFile, setResponse, setOpen}) {
const uploadFile = () => {
        const formData = new FormData();
        console.log(selectedFile);
        formData.append("file", selectedFile)
        axios.post('/upload_file', formData).then(response => setResponse(response))
            .catch(err =>setResponse(err));
        let user = 1234567890;
        let audit_event = `${user} uploaded ${selectedFile.file_name}`
        console.log(audit_event)
        axios.post('/action_audit', null,{params: {user_id: user, audit_event: audit_event}})
            .then(response => console.log(response)).catch(err => console.warn(err))
        setShow(!show);
        setOpen(true);

    }


  const handleClose = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={show}>
        Open alert dialog
      </Button>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upload File?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to upload ${selectedFile.name}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={uploadFile} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
