// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';

import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import {useState} from "react";
import Button from "@mui/material/Button";
import PreviewFileDataGrid from "./PreviewFileDataGrid"
import {archive_columns} from "./constants/ArchiveConstants";
import Paper from "@mui/material/Paper";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
    height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function ArchiveDataGrid(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [rowValue, setRowValue] = useState('')
    const specific_columns = [
        ...props.specific_columns,
            {field:'Preview File', renderCell: ({ rowNode, row }) => (
            rowNode.type === "group" ? null :
                <Button variant="contained" onClick={()=>{
                    setModalOpen(true)
                    setRowValue(row)
                    console.log(row);
                }}>
                    Details
                </Button>)}
    ]

    return (
        <div style={{height: 700, width: '100%', paddingTop: '20px'}}>
            <DataGridPremium
                columns={[...specific_columns]}
                initialState={{
                    rowGrouping: {
                        model: ['product'],
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false
                        }
                    }
                }}
                showCellVerticalBorder={true}
                rows={props.data}
                slots={{
                    toolbar: GridToolbar,
                }}
            />
            <Modal
                open={modalOpen}
            >
                <Box sx={style} style={{height: "auto", width: "90%"}}>
                    <PreviewFileDataGrid
                                         data={props.data}
                                         specific_columns={props.specific_columns}
                                         setShow={setModalOpen}
                                         rowValue={rowValue}
                    />
                </Box>
            </Modal>
        </div>
    );
}
