export const tagDropdownOptionsCostType = [
    {
        name: "All",
        value: "All",
        index: 0
    }
    ,{
        name: "CDA",
        value: "CDA",
        index: 1
    },
    {
        name: "DPA",
        value: "DPA",
        index: 2
    },
    {
        name: "Labor",
        value: "Labor",
        index: 3
    },
    {
        name: "Non Labor",
        value: "N/L",
        index: 4
    },
    {
        name: "Travel",
        value: "Travel",
        index: 5
    },
    {
        name: "CSS",
        value: "CSS",
        index: 6
    }
]
export const portfolioType = [
    {
        name: "All",
        value: "All",
        index: 0
    },
    {
        name: "Energy",
        value: "Energy",
        index: 1
    },
    {
        name: "Fuels and Lubes",
        value: "Fuels and Lubes",
        index: 2
    }
]

export const subPortfolioType = [
    {
        name: "All",
        value: "All",
        index: 0
    },
    {
        name: "Mobility Fuels",
        value: "Mobility Fuels",
        index: 1
    },
    {
        name: "NPRE",
        value: "NPRE",
        index: 2
    },
    {
        name: "Private Party",
        value: "Private Party",
        index: 3
    },
    {
        name: "Other",
        value: "Other",
        index: 4
    }
]


export const project_tag = [
    {
        name: "All",
        value: "All",
        index: 0
    },
    {
        name: "TA&EI",
        value: "TA&EI",
        index: 1
    },
    {
        name: "LPSC",
        value: "LPSC",
        index: 2
    },
    {
        name: "Prog_Mgmt",
        value: "Prog_Mgmt",
        index: 3
    },
    {
        name: "BFM",
        value: "BFM",
        index: 4
    },
    {
        name: "PPE",
        value: "PPE",
        index: 5
    },
    {
        name: "OE_Core",
        value: "OE_Core",
        index: 6
    }
]

export const departmentType = [
    {
        name: "All",
        value: "All",
        index: 0
    },
    {
        name: "Systems Safety",
        value: "Systems Safety",
        index: 1
    },
    {
        name: "Air Systems Integration",
        value: "Air Systems Integration",
        index: 2
    },
    {
        name: "Program Management Support",
        value: "Program Management Support",
        index: 3
    }
]
