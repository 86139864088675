import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState} from "react";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

export default function AutocompleteEmail(props) {
    const [userData, setUserData] = useState('');
    const [userDataReceived, setUserDataReceived] = useState(false);
    const handleChangeLocally = (event, emailValue) => {
        props.onChange(emailValue)
    }

    async function get_users() {

        try {
            const data = await axios.get('/get_all_users');
            setUserData(data.data);
            setUserDataReceived(true);



        } catch (e) {
            console.log(e);
        }
    };


    useEffect(() => {
        get_users()
    }, []);

    if (userDataReceived === false) {
        return <div className={'loading-container'} style={{display: 'flex', justifyContent:'center', alignItems:'center' }}>
            <CircularProgress size={"25px"}/>
        </div>
    }


    return (
        <div id={'email-autocomplete'}>

            <Autocomplete
                disablePortal
                id="email-combo-box"
                value={props.userEmail}
                options={userData.map(user => user.email)}
                defaultValue={""}
                sx={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Email"/>}
                onChange={handleChangeLocally}
            />
        </div>
    );
}