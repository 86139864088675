import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



function UserGroupSelector(props) {
    const handleChangeLocally = (event) => {
        props.onChange(event)
    }

    return (
        <Box sx={{minWidth: 120}}>
            <FormControl fullWidth>
                <InputLabel id="user-group-selector">{props.inputLabel}</InputLabel>
                <Select
                    labelId="user-group-selector"
                    id="user-group-select"
                    value={props.user}
                    label={props.label}
                    onChange={handleChangeLocally}
                    defaultValue={props.defaultValue}

                >
                    {props.items}
                </Select>
            </FormControl>
        </Box>
    );
}

export default UserGroupSelector
