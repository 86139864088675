import './App.css';
import React, {useState} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import {MantineProvider} from "@mantine/core";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import HomePage from "./components/HomePage";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import PortfolioManagementLandingPage from "./components/ProjectPlanning/PortfolioManagementLandingPage";
import AccountManagement from "./components/AccountManagement/AccountManagement";
import FileUpload from "./components/File Drop-off/FileUpload";
import DataGridProjects from "./components/Benchmarks/DataGridProjects";
import BurnRate from "./components/Benchmarks/BurnRate";


//import { LicenseInfo } from '@mui/x-license-pro';
//import {license_key} from "./license";



const theme = createTheme({
    palette: {
        primary: {
            light: '#1e1e86',
            main: '#000080',
            dark: '#02025c',
            contrastText: '#fff',
        },
        secondary: {
            light: '#fff595',
            main: '#ffd900',
            dark: '#ffa700',
            contrastText: '#000080',
        },
    },
});


//LicenseInfo.setLicenseKey(license_key);

function App() {

    const [show_side_nav, set_show_side_nav] = React.useState(true)
    let side_nav = null;
    let wrapper_class = 'wrapper-extended';
    if (show_side_nav) {
        side_nav = (
            <div className={'side_nav'}>
                <div>
                    <SideNav/>
                </div>
            </div>)
    } else {
        side_nav = (<div/>)
        wrapper_class = 'wrapper-collapsed'
    }
    return (
        <MantineProvider>
            <Router basename={'/'}>
                <div className="App">
                    <ThemeProvider theme={theme}>
                        <div className={wrapper_class}>
                            <div className={'side-nav-container'}>
                                {side_nav}
                            </div>
                            <div className={"top-nav-container"}>
                                <div className={'side-nav-control'}>
                                    <Button color={'primary'} variant={"contained"} onClick={() => {
                                        set_show_side_nav(!show_side_nav)
                                    }}><MenuIcon color={'secondary'}/></Button>
                                </div>
                                <div className={'top-nav-body'}>
                                    <TopNav/>
                                </div>
                            </div>
                            <div className={"body-wrapper"}>
                                <Routes>
                                    <Route path={'/'} element={<HomePage/>}/>
                                    <Route path={'/portfolio_management'} element={<PortfolioManagementLandingPage/>}/>
                                    <Route path={'/account_management/'} element={<AccountManagement/>}/>
                                    <Route path={"/file_upload"} element={<FileUpload/>}/>
                                    <Route path={"/benchmarks"} element={<BurnRate/>}/>
                                </Routes>
                            </div>
                        </div>
                    </ThemeProvider>
                </div>
            </Router>
        </MantineProvider>
    );
}

export default App;
