import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGridPro} from '@mui/x-data-grid-pro';
import './BurnRate.css'
import useSpendingStatus from '../../hooks/useSpendingStatus';

export default function DataGridProjects(props) {

    const getRowClassName = (params) => {
        const {row} = params;
        if (row.status === 'overspent') {
            return 'overSpentRow';
        } else if (row.status === 'underspent') {
            return 'underSpentRow';
        }
        return '';
    };


    const columns = [
        {field: "project_name", headerName: "Project", width: 150},
        {field: "product", headerName: "Product", width: 300},
        {field: "portfolio", headerName: "Portfolio", width: 150},
        {field: "sub_portfolio", headerName: "Sub-Portfolio"},
        {field: "department", headerName: "Department", width: 150},
        {field: "project_start", headerName: "Start", width: 150},
        {field: "project_end", headerName: "End", width: 150},
        {field: "budget", headerName: "Budgeted", width: 150},
        {field: "planned", headerName: "Planned", width: 150},
        {field: "network", headerName: "Network", width: 150},
        {field: "activity", headerName: "Activity", width: 150},
        {field: "as_of_date", headerName: "As Of Date", width: 150},
        {field: "commitments", headerName: "Commitments", width: 150},
        {
            field: 'erp_costs',
            headerName: 'ERP Costs',
            type: 'number',
            width: 180,
        },
    ]

    const spendingData = useSpendingStatus(props.rows);

    return (
        <Box>
            <DataGridPro
                columns={columns}
                rows={spendingData}
                loading={props.loading}
                getRowClassName={getRowClassName}
                autosizeOptions={{
                    columns: ["project_name", "project_start", "project_end", "portfolio", "sub_portfolio", "erp_date"],
                    includeOutliers: true,
                    includeHeaders: true
                }}
            />
        </Box>
    );
}