import { useState, useEffect } from 'react';

const useFilter = (data, filterPredicate) => {
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const {
            cost_line_type,
            portfolio,
            sub_portfolio,
            department,
            min_date,
            max_date
        } = filterPredicate;

        let result = data || [];

        if (cost_line_type && cost_line_type !== 'All') {
            result = result.filter(item => item.cost_line_type === cost_line_type);
        }

        if (department && department !== 'All') {
            result = result.filter(item => item.department === department);
        }

        if (portfolio && portfolio !== 'All') {
            result = result.filter(item => item.portfolio === portfolio);
        }

        if (sub_portfolio && sub_portfolio !== 'All') {
            result = result.filter(item => item.sub_portfolio === sub_portfolio);
        }

        if (min_date != null && max_date != null) {
            const minDate = new Date(min_date);
            const maxDate = new Date(max_date);
            result = result.filter(item => {
                const itemDate = new Date(item.calendar_date);
                return itemDate >= minDate && itemDate <= maxDate;
            });
        }

        setFilteredData(result);
    }, [data, filterPredicate]);

    return filteredData;
};

export default useFilter;
