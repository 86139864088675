import Button from "@mui/material/Button";
import {useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import * as React from "react";
import ProjectPlanningForm from "../ProjectPlanning2/ProjectPlanningForm";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function PortfolioManagementLandingPage() {
    const [showDialog, setShowDialog] = useState(false)
    const toggleDialog = () => {
        setShowDialog(!showDialog)
    }
    return (
        <div>
            <Button variant={'contained'} onClick={() => setShowDialog(!showDialog)}>Create Project Plan</Button>
            <Modal open={showDialog}>
                <Box sx={style}>
                    <ProjectPlanningForm
                        dialogControl={toggleDialog}
                    />
                </Box>
            </Modal>
        </div>
    )
}
