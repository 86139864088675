import React, {Component} from 'react';
import Divider from '@mui/material/Divider';
import './css/SideNav.css'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CottageIcon from '@mui/icons-material/Cottage';
import WorkIcon from '@mui/icons-material/Work';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Link } from "react-router-dom";
import NavairLogo from '../images/navair-seal-488.jpg'
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/QueryStats';



// this component manages the side navigation
export default function SideNav() {

    return (
        <div className={"side-nav"}>
            <Stack spacing={2} direction="column">
                <div className={"side-nav-buffer"}>
                    <img alt={"NAVAIR"} src={NavairLogo} height={100} width={100}/>
                </div>
                <Divider/>
                <Link className={"side-nav-link"} to={"/"}>
                    <Button className={"side-nav-link"} variant="contained">
                        <CottageIcon/>
                        &nbsp; Home
                    </Button>
                </Link>
                <Link to={"/portfolio_management"}>
                    <Button className={"side-nav-link"} variant="contained">
                        <WorkIcon/>
                        &nbsp; Project Management
                    </Button>
                </Link>
                <Link to={"/account_management"}>
                    <Button className={"side-nav-link"} variant="contained">
                        <AccountBoxIcon/>
                        &nbsp; Account Management
                    </Button>
                </Link>
                <Link to={"/file_upload"}>
                    <Button className={"side-nav-link"} variant="contained">
                        <FileUploadIcon/>
                        &nbsp; File Upload
                    </Button>
                </Link>
                <Link to={"/report_details"}>
                    <Button className={"side-nav-link"} variant={"contained"}>
                        <InfoIcon/>
                        &nbsp; Reports
                    </Button>
                </Link>
                <Link to={"/benchmarks"}>
                    <Button className={"side-nav-link"} variant={"contained"}>
                        <QueryStatsIcon/>
                        &nbsp; Benchmarks
                    </Button>
                </Link>
            </Stack>
        </div>
    );

}
