import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Stack from '@mui/material/Stack';
import axios from "axios";
import {useState, useEffect} from "react";
import FileAlertDialog from "./FileAlertDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {archive_columns} from "./constants/ArchiveConstants"
import ArchiveDataGrid from "./ArchiveDataGrid"
import * as React from "react";
import FileUploadSnackBar from "./FileUploadSnackBar";

const Input = styled('input')({
    display: 'none',
});


export default function FileUpload() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [show, setShow] = useState(false);
    const [archiveDataReceived, setArchiveDataReceived] = useState(false);
    const [archivedFiles, setArchivedFiles] = useState(null);
    const [response, setResponse] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
            async function get_portfolio_data() {
                const data = await axios.get('/get_archived_files')
                setArchivedFiles(data.data);
                setArchiveDataReceived(true);
            }

            get_portfolio_data();
        },
        [])
    if (archiveDataReceived === false) {
        return <div className={'loading-container'}><CircularProgress size={"200px"}/></div>
    } else {
        return (
            <div className={"upload-container"}>
                <div style={{display: "flex", justifyContent: "right"}}>

                    <label htmlFor="icon-button-file">
                        <Input accept="file" id="icon-button-file" type="file"
                               onChange={e => {
                                   setSelectedFile(e.target.files[0]);
                                   setShow(!show);
                               }
                               }/> {show ?
                        <FileAlertDialog
                            show={show}
                            setShow={setShow}
                            selectedFile={selectedFile}
                            setResponse={setResponse}
                            setOpen={setOpen}
                        />
                         : null}
                        {response ? <FileUploadSnackBar message={response.data ? response.data : response.response.data}
                                                        status={response.status ? response.status : response.response.status}
                                                        open={open} setOpen={setOpen}
                        />
                            : null}
                        <Button color="primary" aria-label="Upload File" component="span" variant={"contained"}>
                            Upload File
                            <FileUploadIcon/>
                        </Button>
                    </label>
                </div>
                <div className={"datagrid-file-archive"}>
                    <ArchiveDataGrid data={archivedFiles} specific_columns={archive_columns}/>
                </div>
            </div>
        );
    }
}
